<template>
    <tr>
        <td class="final-cell">
            <a href="javascript:void(0);" @click="() => {}">
                <font-awesome-icon class="handle" :icon="['fas', 'arrows-alt']"/> 
            </a>
            {{ rowdata.name }}    
        </td>
        <td 
        v-for="(data,i) in rowData"
        :key="`${data}-${i}`"
        class="final-cell">{{ data }}</td>
    </tr>
</template>

<script>
export default {
    props: ["rowdata", "cols", "ecodata"],
    computed: {
        rowData() {
            const cols = [...this.cols].splice(1).map(el => el.code);
            
            return cols.map(el => Object.values(this.ecodata.values.find(obj => Object.prototype.hasOwnProperty.call(obj,el)) || {default: "N/A"})[0]);
        }
    },
}
</script>